<template>
  <div class="about-container Contact-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">联系我们</h1>
        <p class="fs-20">
          <b class="fs-20">电话：</b>
          <a href="tel:021-63120611" class="fs-20">021-63120611</a>
        </p>
        <p class="fs-20">
          <b class="fs-20">邮箱：</b>
          <a href="mailto:tommy.shen@dgsfcn.com" class="fs-20">tommy.shen@dgsfcn.com</a>
        </p>
      </div>
      <div class="banner"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Contact",
  components: {
    Header,
    Footer,
  },

  created() {},
};
</script>
<style lang="scss">
footer {
  padding: 0.520835vw 0px; //10px
  span,
  a {
    font-size: 0.833333vw; //12px
  }
}
</style>
